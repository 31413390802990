import {mapActions, mapGetters, mapMutations} from "vuex";
import Breadcrumbs from '@/components/breadcrumbs/index.vue';
import CustomBreadcrumbs from "@/components/custom-breadcrumbs/index.vue";
export default {
  name: 'media-content',
  components: {
    CustomBreadcrumbs
  },
  data() {
    return {
      currentId: null,
      content: '',
      crumbs:[{title: this.$t('aboutUs'), slug: 'about-us'},{title: this.$t('aboutUsMedia'), slug: ''}]
    }
  },
  created() {
    this.getContent(this.$route.params.slug).then(() => {
      this.content = this.aboutMedia
      this.crumbs.push({title:this.aboutMedia.title,slug:''})
    })
  },
  mounted() {
    console.log('sds');
  },
  computed: {
    ...mapGetters({
      aboutMedia: 'about/aboutMedia'
    }),
    ...mapGetters({
      mediaContent: 'about/mediaContent'
    }),
  },
  watch: {},
  methods: {
    ...mapMutations({}),
    ...mapActions({
      getContent: 'about/GET_ABOUT_MEDIA'
    }),
  },

}
